import Vue from 'vue';
import _ from 'lodash';

const url = '/alfakodos/';
const latestURL = '/latest';
const game = {
  namespaced: true,
  // Initial state
  state: {
    game: {},
    listCheckedGame: {},
    checkedGame: {},
    modalComplete: {},
  },
  // Mutations
  mutations: {
    SET(state, data) {
      state.game = data;
    },
    CHECK_GAME_ALL(state, data) {
      const listGamesTmp = {};
      _.forEach(state.game.clues, (clue, indexClue) => {
        listGamesTmp[indexClue] = data;
      });
      state.listCheckedGame = listGamesTmp;
    },
    CHECK_GAME(state, data) {
      state.checkedGame = { ...data };
    },
    SHOW_MODAL_COMPLETE(state, data) {
      state.modalComplete = { ...data };
    },
  },
  // Actions
  actions: {
    async fetch({ commit }, id) {
      const response = await Vue.prototype.$axios.get(url + id);
      const { data } = response;
      commit('SET', data);
    },
    async latest({ commit }, client) {
      const response = await Vue.prototype.$axios.get(latestURL, {
        params: {
          client,
          game: 'alfakodo',
        },
      });
      const { data } = response;
      commit('SET', data);
    },
    async checkGameAll({ commit }) {
      commit('CHECK_GAME_ALL', true);
      setTimeout(() => {
        commit('CHECK_GAME_ALL', false);
      }, 3000);
    },
    async checkGame({ commit }, data) {
      commit('CHECK_GAME', data);
    },
    async showModalComplete({ commit }, data) {
      commit('SHOW_MODAL_COMPLETE', data);
    },
  },
  // Getters
  getters: {
    getBySide: (state) => (side) => {
      const leftClues = {};
      const rightClues = {};
      _.forEach(state.game.clues, (clue, index) => {
        if (index.charCodeAt(0) <= 77) {
          leftClues[index] = clue;
        } else {
          rightClues[index] = clue;
        }
      });
      let gameSide = {};
      if (side === 'left') {
        gameSide = leftClues;
      } else {
        gameSide = rightClues;
      }
      return gameSide;
    },
    isClueRevealed: (state) => (index) => {
      const found = _.find(state.game.revealed, (obj) => obj === index);
      return found;
    },
    getAnswerByIndex: (state) => (index) => {
      const answer = _.find(state.game.answers, (obj, key) => key === index);
      return answer;
    },
    getClassByIndex: (state) => (index) => {
      let className = '';
      const localData = JSON.parse(localStorage.getItem(state.game.name));
      if (localData) {
        const userAnswer = _.find(localData.input, { id: index });
        const answer = _.find(state.game.answers, (obj, key) => key === index);
        const flagCheck = _.find(state.listCheckedGame, (obj, key) => key === index);
        if (userAnswer) {
          if (flagCheck) {
            if (parseInt(answer, 10) !== parseInt(userAnswer.value, 10)) {
              className = 'incorrect';
            } else {
              className = 'correct';
            }
          }
        }
      }
      return className;
    },
  },
};

export default game;
