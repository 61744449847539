<template>
  <div>
    <!-- Keyboard Input -->
    <div id="mainInputContainer" class="row justify-content-center no-zoom">
      <div id="mainInput">
        <button v-for="i in 10" v-bind:key="i" class="btn no-zoom"
          :style="{ backgroundColor: gameColor}"
          @click.prevent="keyPress(i - 1)">{{ i - 1 }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('session', ['session', 'answers']),
    ...mapState('game', ['game']),
    ...mapGetters('game', ['getAnswerByIndex']),
    ...mapGetters('session', ['getUserAnswer']),
    gameColor() {
      return this.game.color;
    },
  },
  methods: {
    async keyPress(e) {
      const id = this.session.lastCell;
      const currentValue = this.getUserAnswer(id);
      let value = '';
      if (currentValue) {
        console.log(currentValue);
        if (currentValue.value.length > 1) {
          console.log('No can do chief. Please clear the cell first.');
          value = currentValue.value;
        } else {
          value = value.concat(currentValue.value, e);
          console.log(value);
        }
      } else {
        value = e;
      }
      const input = {
        id,
        value,
      };
      await this.$store.dispatch('session/startTimer');
      await this.$store.dispatch('session/addInput', input);
      if (this.session.input) {
        const inputFilter = this.session.input.filter((answer) => answer.value !== '');
        const countInput = inputFilter.length;
        const countRevealed = Object.keys(this.game.revealed).length;
        let count = 0;
        if (countInput + countRevealed === 26) {
          this.$store.dispatch('game/checkGameAll');
          for (let i = 0; i < countInput; i += 1) {
            const sessionId = this.session.input[i].id;
            const sessionValue = this.session.input[i].value;
            if (parseInt(sessionValue, 10) === this.getAnswerByIndex(sessionId)) {
              count += 1;
            }
          }
          if (count + countRevealed === 26) {
            const data = {
              display: true,
            };
            this.$store.dispatch('game/showModalComplete', data);
          }
        }
      }
    },
  },
};
</script>
