<template>
  <v-app id="inspire">
    <v-main v-if="$route.matched.some(({ name }) => name !== 'ListGame')">
      <sidebar></sidebar>
      <v-main>
        <v-container class="fill-height" fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
      <v-footer
        style="background-color: rgb(215, 215, 215);
        padding-top:13px;padding-bottom:11px;"
        :color="`${game.color}`"
        app
      >
        <div
        style="color:#2b2c2d; font-size: 14px; line-height:15px; margin-bottom:0;font-weight: 500;">
          Provided by Auspac Media
        </div>
      </v-footer>
    </v-main>
    <v-main v-if="$route.matched.some(({ name }) => name === 'ListGame')" >
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style lang="sass">
@import 'sass/_variables'
@import 'sass/app'
@import 'sass/beluca'
</style>

<script>
import { mapState } from 'vuex';
import Sidebar from '@/components/Sidebar.vue';
import theme from '@/plugins/themes';

export default {
  components: {
    Sidebar,
  },
  computed: {
    ...mapState('game', ['game']),
  },
  props: {
    source: String,
  },
  beforeCreate() {
    this.$vuetify.theme = theme.theme;
  },
};
</script>
