<template>
  <div id="toolbar">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab dark small v-bind="attrs" v-on="on"
          color="success" @click="checkCell">
          <v-icon dark>fa-check</v-icon>
        </v-btn>
      </template>
      <span>Check Input</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab dark small v-bind="attrs" v-on="on"
          color="info" @click="revealCell">
          <v-icon dark>fa-eye</v-icon>
        </v-btn>
      </template>
      <span>View Answer</span>
    </v-tooltip>

    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab dark small v-bind="attrs" v-on="on"
          color="error" @click="clearCell">
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </template>
      <span>Clear Input</span>
    </v-tooltip>
    <Snackbar :display="notify.display" :text="notify.text"></Snackbar>
  </div>
</template>

<style scoped>
#toolbar {
  margin-bottom: 30px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import Snackbar from '@/components/Snackbar.vue';
import _ from 'lodash';

export default {
  data() {
    return {
      notify: {
        display: false,
        text: '',
      },
    };
  },
  components: { Snackbar },
  computed: {
    ...mapState('session', ['session']),
    ...mapState('game', ['modalComplete']),
    ...mapGetters('game', ['getAnswerByIndex']),
    ...mapGetters('session', ['getUserAnswer']),
  },
  methods: {
    clearCell() {
      const getInput = _.find(this.session.input, { id: this.session.lastCell });
      if (getInput && getInput.value) {
        if (getInput.value !== '') {
          this.$store.dispatch('session/startTimer');
        }
        this.$store.dispatch('session/clearCell');
      }
    },
    async checkCell() {
      const userAnswer = this.getUserAnswer(this.session.lastCell);
      const correctAnswer = this.getAnswerByIndex(this.session.lastCell);
      if (userAnswer) {
        const answer = parseInt(userAnswer.value, 10);
        if (answer === correctAnswer) {
          const id = this.session.lastCell;
          this.notify.display = true;
          this.notify.text = `${correctAnswer} is correct.`;
          await this.$store.dispatch('session/startTimer');
          this.$store.dispatch('session/setAnswer', { value: correctAnswer, id });
          this.$store.dispatch('game/checkGame', { type: 'correct', id });
        } else {
          const id = this.session.lastCell;
          this.notify.display = true;
          this.notify.text = `${answer} is incorrect.`;
          this.$store.dispatch('session/startTimer');
          this.$store.dispatch('session/setAnswer', { value: userAnswer, id });
          this.$store.dispatch('game/checkGame', { type: 'incorrect', id });
        }
      } else {
        const id = this.session.lastCell;
        this.notify.display = true;
        this.notify.text = `No user input found for ${this.session.lastCell}.`;
        this.$store.dispatch('game/checkGame', { type: 'incorrect', id });
      }
    },
    async revealCell() {
      const id = this.session.lastCell;
      if (id) {
        const value = this.getAnswerByIndex(this.session.lastCell);
        const input = {
          id,
          value,
        };
        await this.$store.dispatch('session/startTimer');
        this.$store.dispatch('session/addInput', input);
        this.$store.dispatch('session/setAnswer', { value, id });
        this.$store.dispatch('game/checkGame', { type: 'revealed', id });
        this.notify.display = true;
        this.notify.text = `You have revealed that the answer for ${input.id} is ${input.value}.`;
      } else {
        this.notify.display = true;
        this.notify.text = 'You have not selected an input to reveal.';
      }
    },
  },
};
</script>
