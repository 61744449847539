<template>
  <div id="game">
    <loader v-if="!game.name"></loader>
    <alfakodo v-else></alfakodo>
  </div>
</template>

<script>
import {
  mapState,
} from 'vuex';
import Loader from '../components/Loader.vue';
import Alfakodo from '../components/Alfakodo.vue';

export default {
  components: {
    Loader,
    Alfakodo,
  },
  computed: {
    ...mapState('game', ['game']),
    ...mapState('session', ['session']),
  },
  async mounted() {
    await this.$store.dispatch('game/fetch', this.$route.params.id);
    await this.$store.dispatch('session/fetch');
  },
};
</script>
