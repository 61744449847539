<template>
  <div class="col-sm-12">
    <h6>GAME CLOCK</h6>
    <v-chip v-if="timerFormat"
      :color="`${game.color}`"
      dark>
      {{ timerFormat }}
    </v-chip>
    <div class="wrap-play-pause mt-3">
      <v-btn v-if="!startTimer"
        icon
        color="green" @click="playTimer"
      >
        <v-icon dark>fa-play</v-icon>
      </v-btn>
      <v-btn v-if="startTimer"
        icon
        color="red" @click="pauseTimer"
      >
        <v-icon dark>fa-pause</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      intervalTimer: '',
    };
  },
  computed: {
    ...mapState('session', ['session', 'startTimer', 'timerFormat']),
    ...mapState('game', ['game']),
  },
  watch: {
    startTimer(flag) {
      if (flag) {
        this.intervalTimer = setInterval(() => {
          this.fetchTimer();
        }, 1000);
      } else {
        clearInterval(this.intervalTimer);
      }
    },
  },
  methods: {
    fetchTimer() {
      this.$store.dispatch('session/fetchTimer');
    },
    async playTimer() {
      await this.$store.dispatch('session/startTimer');
    },
    async pauseTimer() {
      await this.$store.dispatch('session/pauseTimer');
    },
  },
};
</script>
