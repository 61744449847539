<template>
  <div id="gameContainer">
    <toolbar></toolbar>
    <v-row align="center" justify="center">
      <game-column
        cols="6"
        class="gameLeft"
        :side="'left'">
      </game-column>
      <game-column
        cols="6"
        class="gameRight"
        :side="'right'">
      </game-column>
    </v-row>
    <keyboard></keyboard>
    <clock></clock>
    <complete-dialog></complete-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GameColumn from './GameColumn.vue';
import Toolbar from './Toolbar.vue';
import Keyboard from './Keyboard.vue';
import Clock from './Clock.vue';
import completeDialog from './diaCompleteGame.vue';

export default {
  components: {
    GameColumn,
    Toolbar,
    Keyboard,
    Clock,
    completeDialog,
  },
  data() {
    return {
      userInput: [],
      checked: [],
    };
  },
  computed: {
    ...mapState('game', ['game']),
    ...mapState('session', ['session', 'answers']),
  },
  watch: {
    answers(value) {
      if (value.length + Object.keys(this.game.revealed).length === 26) {
        const data = {
          display: true,
        };
        this.$store.dispatch('game/showModalComplete', data);
      }
    },
  },
};
</script>
